import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { trTR } from '@mui/x-data-grid'

// Create a theme instance.
const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1836
      }
    },
    palette: {
      background: {
        default: '#FFFFFF'
      },
      primary: {
        main: '#131315'
      },
      secondary: {
        main: 'rgb(238,128,35)'
      },
      text: {
        primary: '#131315'
      }
    },
    spacing: 8,
    shape: {
      borderRadius: 4
    }
  },
  trTR
)

export default responsiveFontSizes(theme)
