import Layout from '../components/layout'
import ContactUs from '../sections/contact_us'
import Experience from '../sections/experience'
import QAndA from '../sections/q_and_a'
import RoadMap from '../sections/roadmap'
import Steps from '../sections/steps'
import Title from '../sections/title'
import WalkingManArea from '../sections/walking_man_area'
import Welcome from '../sections/welcome'

export default function DashboardPage() {
  return (
    <Layout>
      <Title />
      <Welcome />
      <WalkingManArea />
      <Steps />
      <RoadMap />
      <Experience />
      <QAndA />
      <ContactUs />
    </Layout>
  )
}
