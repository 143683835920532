import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AppleSVG from '../assets/apple_svg'
import GooglePlaySvg from '../assets/google_play'
import MobileSVG from '../assets/mobile_svg'
import Container from '../components/container'

export default function Welcome() {
  return (
    <Container size="large">
      <Box
        sx={{
          background: '#F3F3F4',
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          mt: {
            xs: 2,
            sm: 4
          },
          p: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pl: {
              sm: 4,
              lg: 8,
              xl: 12
            },
            justifyContent: 'center',
            maxWidth: {
              xs: '100%',
              sm: '100%',
              md: '50%'
            }
          }}
        >
          <Typography
            sx={{
              lineHeight: '2rem',
              fontWeight: 'bold',
              color: '#DF3E5D',
              fontSize: {
                xs: 26,
                md: 36,
                lg: 48,
                xl: 52
              },
              mt: {
                xs: 1,
                sm: 2,
                md: 4,
                lg: 0
              }
            }}
          >
            AI Destekli Üretkenlik :
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontWeight: 'bold',
              color: '#071B71',
              lineHeight: '5rem',
              fontSize: {
                xs: 22,
                sm: 26,
                md: 34,
                lg: 44,
                xl: 60
              }
            }}
          >
            Görevlerinizi Yönetmenin Akıllı Yolu
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: '#071B71',
              fontSize: {
                xs: 'inherit',
                sm: 'inherit',
                md: 16,
                lg: 20,
                xl: 24
              }
            }}
          >
            Yapay zeka desteği aracılığıyla öğrenilmiş dersleri kaydederek
            dijital kütüphaneler oluşturabilir, verilerinizi hem paydaşların hem
            de diğer proje yöneticilerinin faydalanabileceği bir şekilde
            kullanabilirsiniz.
          </Typography>
          <Stack direction={'row'} gap={1} sx={{ mt: 5 }}>
            <GooglePlaySvg />
            <AppleSVG />
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'flex-start',
              md: 'center'
            },
            width: {
              xs: '100%',
              sm: '100%',
              md: '50%'
            }
          }}
        >
          <MobileSVG />
        </Box>
      </Box>
    </Container>
  )
}
