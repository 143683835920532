import { Box, Button } from '@mui/material'

export default function AppleSVG() {
  return (
    <Button
      href="https://apps.apple.com/us/app/sporticket/id1451813987"
      target={'_blank'}
    >
      <Box sx={{ width: '145px', cursor: 'pointer' }}>
        <img src="apple.svg" style={{ width: '100%' }} />
      </Box>
    </Button>
  )
}
