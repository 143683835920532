import { Box } from '@mui/material'
import RoadMapSVG from '../assets/roadmap_svg'
import Container from '../components/container'

export default function RoadMap() {
  return (
    <Container size="full">
      <Box
        display={'flex'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          ml: {
            xs: -4,
            sm: -6,
            md: -10,
            lg: -16,
            xl: -20
          },
          mt: 2
        }}
      >
        <RoadMapSVG />
      </Box>
    </Container>
  )
}
