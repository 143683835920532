import { Box } from '@mui/system'
import WalkingManSVG from '../assets/walking_man'
import WalkingManTextSVG from '../assets/walking_man_text'
import Container from '../components/container'

export default function WalkingManArea() {
  return (
    <Container size="medium">
      <Box
        minHeight={{ xs: '600px', xl: '974px' }}
        sx={{
          mt: { xs: 2, sm: 8, md: 8, lg: 16 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: {
            xs: 'center',
            sm: 'flex-start'
          }
        }}
      >
        <WalkingManTextSVG />
        <Box
          position={{ xs: 'relative', sm: 'absolute' }}
          sx={{
            top: { sx: 2, sm: 8, md: 16 },
            left: { xs: 0, sm: '263px', md: '400px', lg: '532px', xl: '700px' }
          }}
        >
          <WalkingManSVG />
        </Box>
      </Box>
    </Container>
  )
}
