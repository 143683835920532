import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Collapse, IconButton, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useState } from 'react'
import Container from '../components/container'

const data = [
  {
    question: 'The Ticket nedir?',
    answer:
      'The Ticket, yapay zeka aracılığıyla önceden hazırlanan veya tasarlanan listelerden yeni bir sınıflandırma yaparak paydaşların ortaklaşa kullanabileceği özelleştirilmiş görev yönetimi ve dijital kütüphane uygulamasıdır. '
  },
  {
    question: 'Neden The Ticket’ı kullanmalıyım?',
    answer:
      'The Ticket, yapay zeka aracılığıyla önceden hazırlanan veya tasarlanan listelerden yeni bir sınıflandırma yaparak paydaşların ortaklaşa kullanabileceği özelleştirilmiş görev yönetimi ve dijital kütüphane uygulamasıdır. '
  }
]
export default function QAndA() {
  return (
    <Container size="medium">
      <Stack mt={{ xs: 4, md: 6, lg: 8, xl: 8 }}>
        <Typography
          fontWeight={'bold'}
          fontSize={{
            xs: 25,
            lg: 35
          }}
          textAlign={'center'}
          color={'#CE4B60'}
        >
          Soru&Cevap
        </Typography>
        <Typography
          fontWeight={'bold'}
          fontSize={{
            xs: 33,
            sm: 44,
            md: 55,
            lg: 66
          }}
          textAlign={'center'}
          color={'#0C1B6C'}
        >
          Sıkça Sorulan Sorular
        </Typography>
      </Stack>
      <br />
      <Stack rowGap={2}>
        {data.map((d, i) => (
          <Card
            key={`q_a_${i}`}
            question={d.question}
            answer={d.answer}
            index={i}
          />
        ))}
      </Stack>
    </Container>
  )
}

function Card({
  question,
  answer,
  index
}: {
  question: string
  answer: string
  index: number
}) {
  const [isOpen, setIsOpen] = useState(index === 0)

  function toggle() {
    setIsOpen((prev) => !prev)
  }
  return (
    <>
      <Collapse in={!isOpen}>
        <Box
          onClick={toggle}
          border={0.5}
          borderRadius={2}
          borderColor={'#E3E3E3'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '110px',
            px: 4
          }}
        >
          <Box width={'80%'}>
            <Typography
              color={'#323232'}
              fontSize={{ xs: 20, sm: 21, md: 22, lg: 25, xl: 25 }}
              fontWeight={'bold'}
            >
              {question}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton>
              <ExpandMoreOutlined sx={{ color: '#323232' }} fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Collapse>
      <Collapse in={isOpen}>
        <Box
          onClick={toggle}
          sx={{
            display: 'flex',
            position: 'relative',
            borderRadius: 4,
            overflow: 'hidden',
            background: '#0C1B6C',
            py: 6,
            px: 4
          }}
        >
          <Box
            sx={{
              width: '32rem',
              height: '32rem',
              borderRadius: 100,
              background: 'white',
              opacity: 0.2,
              position: 'absolute',
              left: '-20rem',
              top: '-28rem',
              zIndex: 99
            }}
          />
          <Stack width={'80%'}>
            <Typography color="white" variant="h6" fontWeight={'bold'}>
              {question}
            </Typography>
            <Typography
              mt={1}
              variant="body2"
              color="white"
              fontSize={{ xs: 12, sm: 13, md: 14, lg: 15, xl: 20 }}
            >
              {answer}
            </Typography>
          </Stack>
          <Box
            sx={{
              width: '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IconButton>
              <ExpandLessOutlined sx={{ color: 'white' }} fontSize="large" />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '32rem',
              height: '32rem',
              borderRadius: 100,
              background: 'white',
              opacity: 0.2,
              position: 'absolute',
              right: '-19rem',
              top: '-26rem',
              zIndex: 99
            }}
          />
        </Box>
      </Collapse>
    </>
  )
}
