import { Box } from '@mui/material'
import { ReactSVG } from 'react-svg'

export default function TheTicketLogo() {
  return (
    <Box sx={{ maxWidth: { xs: '50px', md: '200px' } }}>
      <ReactSVG src="the_ticket_logo.svg" />
    </Box>
  )
}
