import axios from 'axios'

axios.defaults.withCredentials = true

const apiBase = process.env.REACT_APP_APIURL as string

export async function post(url: string, body: Object): Promise<boolean> {
  url = apiBase + url
  try {
    console.log('url', url)
    const axiosInstance = axios.create()
    await axiosInstance.post(url, body)
    return true
  } catch (error) {
    console.log('error', error)
    return false
  }
}
