import { Instagram, YouTube } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IconButton, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import Container from '../components/container'
import { post } from '../utils/api'

const instagramLink =
  'https://www.instagram.com/theticket.app/?igshid=YmMyMTA2M2Y%3D'
const googleLink = '#'
const linkedInLink = '#'
const youtubeLink = 'https://www.youtube.com/@TheTicketApp/featured'

export default function ContactUs() {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string>()
  const [email, setEmail] = useState<string>()

  async function send() {
    if (loading || !email || email.trim().length === 0) {
      return
    }
    setLoading(true)
    const response = await post('/public/contact-us', { email: email.trim() })
    setMessage(response ? 'Talebiniz bize ulaştı' : 'Talebiniz alınamadı')
    if (response) {
      setEmail('')
    }
    setLoading(false)
  }

  return (
    <Container size="large">
      <Box
        sx={{
          mt: 10,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: '100%',
          backgroundColor: '#121B64',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: {
            xs: 5,
            md: 5,
            lg: 10,
            xl: 25
          },
          py: {
            xs: 5,
            md: 10,
            lg: 10,
            xl: 10
          }
        }}
      >
        <Box>
          <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 40 }}>
            BİZE ULAŞIN!
          </Typography>
          <Stack direction={'row'} sx={{ alignItems: 'center' }} columnGap={2}>
            <IconButton href={instagramLink} target={'_blank'}>
              <Instagram fontSize="large" sx={{ color: 'white' }} />
            </IconButton>
            {/* <IconButton>
              <Google fontSize="large" sx={{ color: 'white' }} />
            </IconButton> */}
            {/* <IconButton>
              <LinkedIn fontSize="large" sx={{ color: 'white' }} />
            </IconButton> */}
            <IconButton href={youtubeLink} target={'_blank'}>
              <YouTube fontSize="large" sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </Box>
        <Box mt={{ xs: 2, md: 0 }} display={'flex'} flexDirection="column">
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            rowGap={2}
            sx={{
              background: 'white',
              borderRadius: 4,
              px: 4,
              py: 2
            }}
            columnGap={4}
          >
            <TextField
              fullWidth={true}
              sx={{
                minWidth: '15rem'
              }}
              value={email}
              disabled={loading}
              onChange={(event) => setEmail(event?.target.value)}
              variant="standard"
              label="E-mail adresinizi giriniz"
            />
            <LoadingButton
              loading={loading}
              fullWidth={true}
              sx={{ backgroundColor: '#CE4B60', minWidth: '9rem' }}
              onClick={send}
              variant="contained"
            >
              İletişime Geç
            </LoadingButton>
          </Stack>
          {message ? (
            <Typography mt={1} sx={{ color: 'white' }}>
              {message}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Container>
  )
}
