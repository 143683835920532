import { Stack, Typography } from '@mui/material'
import TheTicketLogo from '../assets/the_ticket_logo'
import Container from '../components/container'

export default function Title() {
  return (
    <Container size="medium">
      <Stack
        direction={{
          xs: 'column',
          sm: 'row'
        }}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: {
            xs: 'center',
            sm: 'space-between'
          },
          alignItems: {
            xs: 'inherit',
            sm: 'flex-end'
          }
        }}
      >
        <TheTicketLogo />
        <Stack
          direction={'row'}
          columnGap={1}
          flexWrap={'wrap'}
          mt={{ xs: 1, sm: 0 }}
        >
          <Typography
            fontSize={{ xs: '0.7rem', sm: '0.85rem', md: '1rem' }}
            component="span"
            color={'#383E93'}
          >
            Learn from yesterday,{' '}
          </Typography>
          <Typography
            fontSize={{ xs: '0.7rem', sm: '0.85rem', md: '1rem' }}
            component="span"
            color={'#EAAA3C'}
          >
            Live for today,{' '}
          </Typography>
          <Typography
            fontSize={{ xs: '0.7rem', sm: '0.85rem', md: '1rem' }}
            component="span"
            color={'#DF3E5D'}
          >
            Hope for Tomorrow
          </Typography>
        </Stack>
      </Stack>
    </Container>
  )
}
