import { Box } from '@mui/material'

export default function WalkingManTextSVG() {
  return (
    <Box
      sx={{
        width: { xs: '263px', md: '400px', lg: '532px', xl: '799px' },
        zIndex: 50
      }}
    >
      <img src="wmt.png" style={{ width: '100%' }} />
    </Box>
  )
}

// <Box sx={{ width: { xs: '263px', lg: '532px', xl: '799px' } }}>
