import { Avatar, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Container from '../components/container'

const data = [
  {
    text: 'The Ticket’ın çevrimiçi ve çevrimdışı desteğiyle her yerde projelerin gelişimini sağlayabiliyorum.',
    name: 'Arif Çetin',
    title: 'Event Manager at Spor İstanbul',
    bgColor: '#FFFFFF',
    textColor: '#999999'
  },
  {
    text: 'Sadece ben değil tüm iş arkadaşlarımla gerçek zamanlı bildirimler alıyor, projeleri en verimli şekilde tamamlıyoruz.',
    name: 'Yasemin Üner',
    title: 'Event Manager - Istanbul Metropolitan Municipality',
    bgColor: '#DF3E5D',
    textColor: '#FFFFFF'
  },
  {
    text: 'Futboldan basketbola, voleyboldan eskrime kadar tüm spor dallarına özel dijital kütüphaneler oluşturup tüm spor dallarına özel yönetim sistemi oluşturduk.',
    name: 'İstanbul Sports Events Inc',
    title: 'İstanbul Sports Events Inc',
    bgColor: '#EAAB3B',
    textColor: '#FFFFFF'
  }
]

export default function Experience() {
  return (
    <Container size="large">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#031547',
          position: 'relative',
          overflow: 'hidden',
          px: {
            xs: 5,
            md: 5,
            lg: 10,
            xl: 25
          },
          py: {
            xs: 5,
            md: 10,
            lg: 15,
            xl: 20
          }
        }}
      >
        <Stack
          display={'flex'}
          flexWrap={'wrap'}
          direction={{ md: 'row' }}
          rowGap={5}
          sx={{
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          {data.map((d, i) => (
            <Card
              text={d.text}
              name={d.name}
              title={d.title}
              bgColor={d.bgColor}
              textColor={d.textColor}
              key={`comment_${i}`}
            />
          ))}
        </Stack>
        <Box
          sx={{
            width: '32rem',
            height: '32rem',
            borderRadius: 100,
            background: 'white',
            opacity: 0.1,
            position: 'absolute',
            zIndex: 98,
            top: '-27rem'
          }}
        />
        <Box
          sx={{
            width: '32rem',
            height: '32rem',
            borderRadius: 100,
            background: 'white',
            opacity: 0.1,
            position: 'absolute',
            zIndex: 98,
            left: '-25rem'
          }}
        />
        <Box
          sx={{
            width: '32rem',
            height: '32rem',
            borderRadius: 100,
            background: 'white',
            opacity: 0.1,
            position: 'absolute',
            zIndex: 98,
            left: '-10rem',
            bottom: '-20rem',
            display: {
              xs: 'none',
              xl: 'block'
            }
          }}
        />
        <Box
          sx={{
            width: '32rem',
            height: '32rem',
            borderRadius: 100,
            background: 'white',
            opacity: 0.1,
            position: 'absolute',
            zIndex: 98,
            top: '-30rem',
            right: '5rem',
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        />
        <Box
          sx={{
            width: '32rem',
            height: '32rem',
            borderRadius: 100,
            background: 'white',
            opacity: 0.1,
            position: 'absolute',
            zIndex: 98,
            right: '-25rem',
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        />
        <Box
          sx={{
            width: '32rem',
            height: '32rem',
            borderRadius: 100,
            background: 'white',
            opacity: 0.1,
            position: 'absolute',
            zIndex: 98,
            right: '-10rem',
            bottom: '-20rem',
            display: {
              xs: 'none',
              xl: 'block'
            }
          }}
        />
      </Box>
    </Container>
  )
}

function Card({
  text,
  name,
  title,
  avatar,
  bgColor,
  textColor
}: {
  text: string
  name: string
  title: string
  avatar?: string
  bgColor: string
  textColor: string
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 99
      }}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box
          sx={{
            background: bgColor,
            color: textColor,
            display: 'flex',
            borderRadius: 3,
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            width: {
              xs: '300px',
              md: '250px',
              lg: '300px',
              xl: '417px'
            },
            minHeight: {
              xs: 'inherit',
              sm: 250
            }
          }}
        >
          <Typography
            fontSize={{ xs: 12, sm: 13, md: 14, lg: 15, xl: 20 }}
            textAlign={'center'}
          >
            {text}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 0,
            height: 0,
            mt: -1,
            borderLeft: '25px solid transparent',
            borderRight: '25px solid transparent',
            borderTop: `25px solid ${bgColor}`
          }}
        />
      </Box>
      <Avatar sx={{ width: 100, height: 100, mt: 3 }} src={avatar}></Avatar>
      <Typography fontWeight={'bold'} mt={2} color={'white'} fontSize={18}>
        {name}
      </Typography>
      <Typography color={'#999999'}>{title}</Typography>
    </Box>
  )
}
