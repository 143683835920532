import { BoxProps } from '@mui/material'
import { Box } from '@mui/system'

interface ContainerProps extends BoxProps {
  size: 'small' | 'medium' | 'large' | 'full'
}

const sizeMap = new Map([
  ['small', { xs: '96%', lg: '878px', xl: '1316px' }],
  ['medium', { xs: '96%', lg: '1052px', xl: '1579px' }],
  ['large', { xs: '96%', lg: '1153px', xl: '1729px' }],
  ['full', { xs: '100%', lg: '100%', xl: '100%' }]
])

export default function Container(props: ContainerProps) {
  const width = sizeMap.get(props.size) ?? { xs: '100%', lg: '100%' }

  return (
    <Box
      sx={{
        width,
        height: '100%',
        position: 'relative'
      }}
    >
      {props.children}
    </Box>
  )
}
