import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Container from '../components/container'

const data = [
  {
    text: 'Stratejik hedeflerinize uygun performans değerlendirmeleri hazırlayın.',
    color: '#E99936'
  },
  {
    text: 'Son teslim tarihi, önem durumu ve görev dağılımı takibini sağlayın.',
    color: '#CE4B60'
  },
  {
    text: 'Projelerinize tüm akıllı cihazlardan çevrimiçi veya  çevrimdışı olarak erişin.',
    color: '#3B3E84'
  },
  {
    text: 'Size özel ticket sistemiyle proje akışını kişiselleştirin',
    color: '#E99936'
  },
  {
    text: 'Gerçek zamanlı bildirimlerle akışta kalın.',
    color: '#CE4B60'
  },
  {
    text: 'Stratejik hedeflerinize uygun performans değerlendirmeleri hazırlayın.',
    color: '#3B3E84'
  }
]

export default function Steps() {
  return (
    <Container size="small">
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography
          sx={{
            lineHeight: '2rem',
            fontWeight: 'bold',
            color: '#DF3E5D',
            fontSize: {
              xs: 26,
              md: 36,
              lg: 48,
              xl: 52
            },
            mt: {
              xs: 1,
              sm: 2,
              md: 4,
              lg: 0
            }
          }}
        >
          Adım adım
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: '#071B71',
            fontSize: {
              xs: 28,
              sm: 32,
              md: 40,
              lg: 50,
              xl: 66
            }
          }}
        >
          THE TICKET
        </Typography>
        <Box
          gap={3}
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {data.map((d, i) => (
            <Card key={`step_${i}`} text={d.text} color={d.color} />
          ))}
        </Box>
      </Box>
    </Container>
  )
}

function Card({ text, color }: { text: string; color: string }) {
  return (
    <Box
      width={{
        xs: '270px',
        lg: '270px',
        xl: '411px'
      }}
      height={{
        xs: '270px',
        lg: '270px',
        xl: '411px'
      }}
      borderRadius={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color
      }}
    >
      <Typography
        sx={{ color: 'white', p: { xs: 2, sm: 4, md: 5, lg: 5, xl: 8 } }}
        fontSize={{
          xs: 20,
          lg: 25,
          xl: 30
        }}
        textAlign={'center'}
      >
        {text}
      </Typography>
    </Box>
  )
}
