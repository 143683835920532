import { BoxProps } from '@mui/material'
import { Box } from '@mui/system'

interface LayoutProps extends BoxProps {}

export default function Layout(props: LayoutProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          pt: { xs: 2, md: 3, lg: 4 },
          pb: { xs: 4, md: 6, lg: 8 },
          width: '100%',
          maxWidth: '1920px',
          alignItems: 'center'
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}
